( function( window, document, $ ){

	var $window = $(window),
		$document = $(document);
    
        //Global variables
    var partnerwidth,
        partneralign,
        partnerblockalign,
        partnerleftalign,
        viewportwidth,
        viewportheight;


    $document.ready(function() {
        accordion_toggle();
        sticky_header();
        product_category_toggle();
        init_selects();
        init_tabs();
        filter_buttons();
        woo_cat_open();
        hp_benefits_section();
        sidr_init();
        
        
        $( ".variations_form" ).on( "woocommerce_variation_select_change", function () {
            var variations = [];
            
            $(this).find('select').each(function() {
                var value = $(this).val();
                if (value) {
                    variations.push(value);
                }
            });
            if (variations.length === 0) {
                $('#clear_btn').css('display','none');
            }else{
                $('#clear_btn').css('display','block');
            }
            
        });
    });
    
    $window.scroll(function() {
        sticky_header();
    });
    
    overlay_toggle();
    
    
    function getWindowSize(){
        if (typeof window.innerWidth != 'undefined'){
            viewportwidth = window.innerWidth,
            viewportheight = window.innerHeight
        }else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0){
            viewportwidth = document.documentElement.clientWidth,
            viewportheight = document.documentElement.clientHeight
        }else{
            viewportwidth = document.getElementsByTagName('body')[0].clientWidth,
            viewportheight = document.getElementsByTagName('body')[0].clientHeight
        }
    }

    function overlay_toggle(){
        $document.ready(function() {
            $('.toggle--overlay').on('click', function(e){
                e.preventDefault();
                var headerHeight = $('.header').outerHeight();
                var windowHeight = $window.scrollTop();

                $('body').toggleClass('overlay_open');

                console.log('Header Height' + headerHeight);
                $('.overlay_open .block--screen_overlay').attr('style', 'top:'+headerHeight+'px');
            });
        });
        
        $window.scroll(function() {

            if($('body.overlay_open').length){
                var headerHeight = $('.header').outerHeight();
                $('.overlay_open .block--screen_overlay').attr('style', 'top:'+headerHeight+'px');
            }
        });
    }

    function accordion_toggle(){
        $('.accordion--toggle').on('click', function(e){
            e.preventDefault();
            $(this).parents('section').toggleClass('open');
        });
    }

    
    function sticky_header(){
		var scroll = $(window).scrollTop(),
		    mobile = ( viewportwidth < 960 ) ? true : false,
		    size = $('.header').outerHeight(true);

		if (scroll > size) {
			$('.header').addClass('sticky');
		} else {
			$('.header').removeClass('sticky');
		}
	};

    function product_category_toggle(){
        $('.product-categories').find('.parent--top').each(function(){
            $(this).on('click', function(e){
                if( $(this).siblings('.children').length ){
                    e.preventDefault();
                    $(this).parent('.cat-parent').toggleClass('open');
                }
            });
        });
    }

    function init_selects(){
        var $selects = $('select').not('[name^=attribute]'),
            $fselects = $selects.fancySelect();
    }

    function init_tabs(){
        $('.tabs').tabslet();
    }

    function filter_buttons(){
        $('.filter_button').on('click', function(e){
            e.preventDefault();
            $('.filter_column').toggleClass('open_filter');
            $('.saved_column').removeClass('open_saved');
        });
        $('.saved_button').on('click', function(e){
            e.preventDefault();
            $('.saved_column').toggleClass('open_saved');
            $('.filter_column').removeClass('open_filter');
        });
    }

    function woo_cat_open(){
        $('.cat-parent>a').on('click', function(e){
            e.preventDefault();
            $(this).parent().find('.children').slideToggle();
        })
    }

    function hp_benefits_section(){
        var $slider = $(".block--benefits-slider"),
            $slides = $slider.find('.slide'),
            $toggle = $slider.find('.section--toggle');

            $toggle.on('click',function(e){
                e.preventDefault();
                $slides.removeClass('active');
                $(this).parent().addClass('active');
            });
    }

    function sidr_init(){
        $('.right-menu').sidr({
            side: 'right',
            name: 'sidr-right',
            source : function() {
                return '<div class="sidr-inner"><h1 class="menu_title"><a href="/"><img src="/wp-content/themes/cmpcommercial/assets/img/logo.svg"></a></h1><div><form action="/"><input type="text" name="s" placeholder="What are you looking for?" class="monile_search"></form></div><ul class="sidr-class-main-nav">' + $('.global-header-nav .main-nav').html() + '</ul><ul class="sidr-class-secondary-nav">' + $('.global-secondary-nav .secondary-nav').html() + '</ul></div>';
            },
            onOpen: function() {
                //alert('test');
                $( 'body' ).prepend( '<div class="sidr-overlay"></div>' );
            },
            onOpenEnd: function(){
                $('.sidr-overlay').click(function() {
                    $.sidr('close','sidr-right');
                });
                $("i").addClass('icon-x');
                $("i").removeClass('icon-menu');
            },
            onClose: function(){
                $("i").removeClass('icon-x');
                $("i").addClass('icon-menu');
                $( '.sidr-overlay' ).remove();
            },
            onCloseEnd: function(){
                $( 'body' ).css({
                    "position": "relative",
                    "left": "auto",
                    "top": "auto",
                    "width": "100%"
                });
            }
        });
        $('.dropdown__item .arrow').click(function(e) {
            e.preventDefault();
            $(this).next().slideToggle();
        });
        $('.right-menu').click(function() {
            $.sidr('close','sidr-right');
        });
    }

})( window, document, jQuery );